import React from "react";
import FadeInSectionDownToUp from "../../Components/FadeAnimations/FadeInSectionDownToUp.js";

const AIandMLLAB = () => {
  return (
    <section className="bg-black text-white flex flex-col overflow-hidden">
      <div className="container">
        <div
          className="bg-cover object-cover bg-center flex justify-center w-screen h-screen"
          style={{ backgroundImage: `url('/Cover Image black.jpg')` }}
        >
          <div className="block sm:flex gap-32 py-20 lg:py-40 px-10 max-w-5xl">
            <FadeInSectionDownToUp>
              <div className="flex flex-col justify-start items-start">
                <h1 className="text-5xl sm:text-6xl">
                  Why <br /> Al & ML Lab?
                </h1>
                <div className="mt-10 text-justify">
                  Our Al & ML Innovation Center offers a unique hands-on
                  experience in artificial intelligence and machine learning.
                  Equipped with high-performance tools and guided by experts,
                  students can develop and test cutting-edge Al models and
                  applications, transforming their innovative ideas into
                  reality.
                </div>
              </div>
            </FadeInSectionDownToUp>
            <div className="flex justify-end items-end mt-10 text-justify">
              <FadeInSectionDownToUp>
                Our Al & ML program introduces students to the transformative
                world of artificial intelligence and machine learning. Through a
                blend of theoretical knowledge and practical applications,
                students will gain insights into this rapidly evolving field.
              </FadeInSectionDownToUp>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto max-w-4xl px-5 py-36 sm:py-52 flex justify-center items-center">
        <div className="grid grid-cols-2 gap-10 place-content-center">
          <img
            src="/B0.png"
            alt="program-picture-1"
            className="rounded-3xl w-72 p-5 sm:p-10"
          />
          <img
            src="/B1.jpg"
            alt="program-picture-2"
            className="rounded-3xl w-72"
          />
          <img
            src="/B2.jpg"
            alt="program-picture-3"
            className="rounded-3xl w-72"
          />
          <img
            src="/B3.jpg"
            alt="program-picture-4"
            className="rounded-3xl w-72"
          />
        </div>
      </div>
      <div className="container mx-auto max-w-6xl px-5 flex flex-col justify-center items-center">
        <div className="grid grid-rows-4 sm:grid-rows-3 grid-flow-col gap-10 sm:gap-20">
          <FadeInSectionDownToUp>
            <p>
              <span className="text-[#F56DF5] pb-5 font-bold text-lg ">
                {" "}
                Intelligence Artificial toIntroduction{" "}
              </span>{" "}
              <br /> Gain a foundational understanding of Al concepts.
            </p>
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <p>
              <span className="text-[#F56DF5] pb-5 font-bold text-lg ">
                {" "}
                Basics LearningMachine{" "}
              </span>{" "}
              <br /> Learn the core principles of machine learning.
            </p>
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <p>
              <span className="text-[#F56DF5] pb-5 font-bold text-lg ">
                {" "}
                Analytics & ScienceData{" "}
              </span>{" "}
              <br /> Explore the techniques for data collection, analysis, and
              interpretation.
            </p>
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <p>
              <span className="text-[#F56DF5] pb-5 font-bold text-lg ">
                {" "}
                ML & AI forPython{" "}
              </span>{" "}
              <br /> Develop proficiency in Python programming for Al and ML
              projects.
            </p>
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <p>
              <span className="text-[#F56DF5] pb-5 font-bold text-lg ">
                {" "}
                Learning Deep & NetworksNeural{" "}
              </span>{" "}
              <br /> Dive into the complexities of neural networks and deep
              learning algorithms.
            </p>
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <p>
              <span className="text-[#F56DF5] pb-5 font-bold text-lg ">
                {" "}
                ML & AI in ProjectsHands-on
              </span>{" "}
              <br /> Apply your knowledge through practical projects, gaining
              real-world experience.
            </p>
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <p>
              <span className="text-[#F56DF5] pb-5 font-bold text-lg ">
                {" "}
                Applications & EthicsAl{" "}
              </span>{" "}
              <br /> Discuss the ethical considerations and diverse applications
              of Al in various industries.
            </p>
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <p>
              <span className="text-[#F56DF5] pb-5 font-bold text-lg ">
                {" "}
                (NLP) Processing LanguageNatural{" "}
              </span>{" "}
              <br /> Understand how machines comprehend and process human
              language.
            </p>
          </FadeInSectionDownToUp>
        </div>
      </div>
      <div className="container mx-auto max-w-4xl px-5 py-36 sm:py-52 flex flex-col justify-center items-center">
        <h1 className="font-bold text-4xl mb-14">
          Facilities <br /> and{" "}
          <span className="text-[#F56DF5]"> Equipments.</span>
        </h1>
        <div className="grid grid-rows-4 grid-flow-col gap-10 justify-items-start place-items-center ">
          <FadeInSectionDownToUp>
            <img src="/icon 1.png" alt="program-picture-1" className="w-52 " />
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <img src="/icon 2.png" alt="program-picture-2" className="w-52" />
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <img src="/icon 3.png" alt="program-picture-3" className="w-52" />
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <img src="/icon 4.png" alt="program-picture-4" className="w-52" />
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <p>High-Performance Clusters Computing</p>
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <p>Deep Learning Frameworks</p>
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <p>Data Analytics and Visualization Tools</p>
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <p>AI Research Platforms</p>
          </FadeInSectionDownToUp>
        </div>
      </div>
    </section>
  );
};

export default AIandMLLAB;
