import React from "react";
import FadeInSectionDownToUp from "../../Components/FadeAnimations/FadeInSectionDownToUp.js";

const SRLab = () => {
  return (
    <section className="flex flex-col overflow-hidden items-center justify-center bg-black text-white">
      <div className="w-full h-full">
        <div className="max-w-4xl px-5 mb-10 mx-auto">
          <div className="container h-screen flex flex-col justify-center items-start">
            <FadeInSectionDownToUp>
              <h1 className="tracking-normal leading-tight text-transparent text-5xl font-bold bg-gradient-to-b from-pink-600 to-violet-600 bg-clip-text">
                Why <br /> STEM & <br /> Robotics Lab?
              </h1>
            </FadeInSectionDownToUp>
            <FadeInSectionDownToUp>
              <p className="mt-10 text-justify">
                Our exclusive lab is equipped with suitable workstations, a
                reliable power supply, and comprehensive safety and testing
                equipment, allowing young minds to assemble CanSats or CubeSats,
                and build their own drones, gliders, and RC planes. The
                availability of necessary resources ensures they can achieve
                accurate results. The lab is meticulously maintained to ensure
                children's safety, providing them the freedom to explore,
                create, and develop. Working in the lab enables focused
                attention on projects without distractions, fostering a positive
                learning environment that promotes good learning and happy
                minds.
              </p>
            </FadeInSectionDownToUp>
          </div>
          <div className="container flex flex-col justify-center items-center">
            <div className="pb-20" style={{ maxWidth: "600px" }}>
              <FadeInSectionDownToUp>
                <p className="flex flex-col text-xl leading-10">
                  Center of Excellence in
                  <span className="tracking-normal text-transparent text-5xl sm:text-6xl font-bold bg-gradient-to-b from-pink-600 to-violet-600 bg-clip-text">
                    Robotics & STEM.
                  </span>
                </p>
              </FadeInSectionDownToUp>
              <FadeInSectionDownToUp>
                <p className="mt-8 text-justify">
                  The school's robotics lab incorporates machine learning,
                  sensor integration, and autonomous navigation, honing skills
                  in robot design,construction, and programming for future
                  innovation leadership.
                </p>
              </FadeInSectionDownToUp>
            </div>
            <FadeInSectionDownToUp>
              <p className="text-3xl font-bold">What's Involved</p>
            </FadeInSectionDownToUp>
            <div className="py-20">
              <video width="700" autoPlay loop className="rounded-3xl">
                <source src="https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Green%20Robot%20Animate.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {/* <center>
              <img src={RobotAnimate} alt="Robot" />
              </center> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="-mt-16 sm:-mt-32  px-10"> 
        <img src="/ROBOT ANIME.png" alt="anime-img" className="" width="1000" />
      </div> */}
      <div
        className="container w-screen h-full flex flex-col items-center justify-center"
        style={{ maxWidth: "800px" }}
      >
        <div className="py-20 px-10">
          <FadeInSectionDownToUp>
            <p className="text-lg">
              Rise above traditional <br /> Robotics labs and innovate with our
            </p>
            <FadeInSectionDownToUp>
              <h1 className="text-5xl font-bold mt-3">
                Intellect <span className="text-pink-600">Program!</span>
              </h1>
            </FadeInSectionDownToUp>
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <p className="mt-8">
              Begin an engaging educational voyage, mastering cutting-edge
              robotics in our state-of-the-art facility to tackle practical
              problems and forge the path to innovation
            </p>
          </FadeInSectionDownToUp>
        </div>
        <div className="flex-row sm:flex gap-10">
          <div className="w-56">
            <img
              src="/BOX 1.jpg"
              alt="box-img"
              className="rounded-3xl"
              width="230"
            />
            <FadeInSectionDownToUp>
              <p className="p-3 mb-10 text-justify">
                Equipped with state-of- the-art infrastructure and project-based
                learning kits for hands-on exploration
              </p>
            </FadeInSectionDownToUp>
          </div>
          <div className="w-56">
            <img
              src="/BOX 2.jpg"
              alt="box-img"
              className="rounded-3xl"
              width="230"
            />
            <FadeInSectionDownToUp>
              <p className="p-3 mb-10 text-justify">
                Explore Programming in embedded systems, Python development, and
                Al-focused coding, and exploring computer vision
              </p>
            </FadeInSectionDownToUp>
          </div>
          <div className="w-56">
            <img
              src="/BOX 3.jpg"
              alt="box-img"
              className="rounded-3xl"
              width="230"
            />
            <FadeInSectionDownToUp>
              <p className="p-3 mb-10 text-justify">
                Year-long curriculum integrating NEP 2020 and IB/CBSE skill
                subjects for student
              </p>
            </FadeInSectionDownToUp>
          </div>
        </div>
        <div className="pt-0 max-sm:pt-10 text-center mt-10">
          <FadeInSectionDownToUp>
            <p className="text-3xl sm:text-5xl">
              Unlock Your Potential with <br /> Our{" "}
              <span className="bg-gradient-to-b from-pink-600 to-violet-600 bg-clip-text text-transparent font-bold leading-tight">
                {" "}
                STEM and Robotics.
              </span>
            </p>
          </FadeInSectionDownToUp>
          <FadeInSectionDownToUp>
            <p className="mt-7 sm:mt-10 text-sm">
              WHERE . IMAGINATION . MEETS . INNOVATION
            </p>
          </FadeInSectionDownToUp>
        </div>
      </div>
      <div className="mx-5 py-20">
        <div
          className="p-10 flex justify-start items-center rounded-3xl bg-cover bg-center object-cover"
          style={{
            backgroundImage: `url("/BOX 4.jpg")`,
            height: "500px",
            width: "90vw",
          }}
        >
          <FadeInSectionDownToUp>
            <p className="text-white max-w-md text-justify">
              Dive into hands-on projects and expert-l sessions, exploring the
              realms of science, technology, engineering, and math. Gain
              practical skills and critical insights to fuel your passion for
              discovery. Join us in sha the future through creative problem-sol
              and cutting-edge exploration. With ours the possibilities are
              limitless, and your is journey towards excellence begins here
            </p>
          </FadeInSectionDownToUp>
        </div>
      </div>
    </section>
  );
};

export default SRLab;
