import React from 'react'
import AiAndMl1 from '../../../assets/Projects/AI & ML/AI&ML - ITAI.jpg';
import AiAndMl2 from '../../../assets/Projects/AI & ML/AI&ML - MLB.jpg';
import AiAndMl3 from '../../../assets/Projects/AI & ML/AI&ML - DS.jpg';
import AiAndMl4 from '../../../assets/Projects/AI & ML/AI&ML - NLP.jpg';
import AiAndMl5 from '../../../assets/Projects/AI & ML/AI&ML - NN&DL.jpg';
import AiAndMl6 from '../../../assets/Projects/AI & ML/AI&ML - HOPIA&ML.jpg';
import FadeInSectionDownToUp from '../../FadeAnimations/FadeInSectionDownToUp';

export default function AiAndMl() {
    return (
        <div>
            <div className="min-h-screen bg-white dark:bg-black p-5">
                <div className="pl-96 xl:pl-96 lg:pl-36 max-sm:pl-0 bg-white dark:bg-black pt-20">
                    <div className="text-start max-sm:text-start">
                        <FadeInSectionDownToUp>
                            <h1 className="from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent font-bold text-5xl md:text-6xl lg:text-7xl mr-0 lg:mr-[90px]">
                                AI & ML.
                            </h1>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
                <div className='flex justify-center items-center max-w-[700px] mx-auto py-10'>
                    <FadeInSectionDownToUp>
                        <p className="text-gray-600 dark:text-gray-400 mt-4 max-sm:mt-10 text-lg md:text-xl lg:text-2xl w-full text-justify">
                            Artificial Intelligence and Machine Learning, where your child can become a tech innovator. our program enables young people to develop their critical thinking and creative thinking skills.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className="max-w-[700px] mx-auto">
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                        {/* Column 1 */}
                        <div className="flex flex-col space-y-4">
                            {/* Large container */}
                            <FadeInSectionDownToUp>
                                <div className="relative bg-gray-800 rounded-xl overflow-hidden h-60 md:h-[400px]">
                                    <img
                                        src={AiAndMl1}
                                        alt="Main Feature 1"
                                        className="object-cover w-full h-full"
                                    />
                                    <div className="absolute inset-0  flex flex-col  bg-black bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">

                                        <h2 className="text-3xl max-sm:text-lg font-bold max-sm:font-normal text-left mt-56 max-sm:mt-44 pl-5 max-sm:pl-5 z-50">
                                            Introduction to AI.
                                        </h2>
                                        <p className="text-justify text-[12px] w-56 mt-2  pl-5 max-sm:hidden">
                                            {/* AR and VR at MH Intellect revolutionize experiences, blending reality with digital innovation for unparalleled learning, training, and interactive adventures. */}
                                            Uncover the potential of artificial intelligence! Our curriculum familiarizes students with AI and machine learning, igniting their imagination and equipping them to take charge in the tech-driven world of the future.
                                        </p>

                                    </div>
                                    <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black"></div>
                                </div>
                            </FadeInSectionDownToUp>
                            {/* Small containers */}
                            <FadeInSectionDownToUp>
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="relative bg-gray-800 rounded-xl overflow-hidden h-40 md:h-[200px]">
                                        <img
                                            src={AiAndMl2}
                                            alt="Feature 1"
                                            className="object-cover w-full h-full"
                                        />
                                        <div className="absolute inset-0 flex flex-col items-center max-sm:items-start justify-center bg-black bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">
                                            <h2 className="text-xl max-sm:text-lg font-bold max-sm:font-normal text-left max-sm:mt-20 pl-5 max-sm:pl-5 [word-spacing:2px] z-50">
                                                Machine<br />Learning Basics.
                                            </h2>
                                            <p className="text-justify pl-1 xl:px-5 text-xs max-sm:hidden">
                                                {/* STEM education at MH-B inspires innovation, creativity, and problem-solving skills. */}
                                                where computers become superheroes, learning to recognize, predict, and create fun adventures!
                                            </p>
                                        </div>
                                        <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black"></div>
                                    </div>
                                    <div className="relative bg-gray-800 rounded-xl overflow-hidden h-40 md:h-[200px]">
                                        <img
                                            src={AiAndMl3}
                                            alt="Feature 2"
                                            className="object-cover w-full h-full"
                                        />
                                        <div className="absolute inset-0 flex flex-col items-start justify-center bg-black bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">
                                            <h2 className="text-xl font-bold max-sm:font-normal text-left mt-7 pl-5 max-sm:mt-20 [word-spacing:2px] z-50">
                                                Data Science<br />& Analytics.
                                            </h2>
                                            <p className="text-justify px-5 text-xs max-sm:hidden">
                                                {/* STEM education at MH-B inspires innovation, creativity, and problem-solving skills. */}
                                                Kids explore the world of data, uncovering secrets and patterns that fuel creativity and innovation.
                                            </p>
                                        </div>
                                        <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black"></div>
                                    </div>
                                </div>
                            </FadeInSectionDownToUp>
                        </div>

                        {/* Column 2 */}
                        <div className="flex flex-col space-y-4">
                            {/* Small containers */}
                            <FadeInSectionDownToUp>
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="relative bg-gray-800 rounded-xl overflow-hidden h-40 md:h-[200px]">
                                        <img
                                            src={AiAndMl4}
                                            alt="Feature 3"
                                            className="object-cover w-full h-full"
                                        />
                                        <div className="absolute inset-0 flex flex-col items-start justify-center bg-black bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">
                                            <h2 className="text-xl font-bold max-sm:font-normal max-sm:mt-10 text-left pl-3 [word-spacing:2px] z-50">
                                                NLP.
                                            </h2>
                                            <p className="text-justify px-3 text-xs max-sm:hidden">
                                                {/* STEM education at MH-B inspires innovation, creativity, and problem-solving skills. */}
                                                Learn how AI understands human language with Natural Language Processing. Our program empowers students to innovate in communication and technology.
                                                {/* Learn how Natural Language Processing empowers from AI to understand human language, equipping students to innovate in technology and communication */}
                                            </p>
                                        </div>
                                        <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black"></div>
                                    </div>
                                    <div className="relative bg-gray-800 rounded-xl overflow-hidden h-40 md:h-[200px]">
                                        <img
                                            src={AiAndMl5}
                                            alt="Feature 4"
                                            className="object-cover w-full h-full"
                                        />
                                        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">
                                            <h2 className="text-xl max-sm:text-lg font-bold max-sm:font-normal max-sm:mt-10 text-left pl-5 max-sm:pl-0 [word-spacing:2px] z-50">
                                                Neural Network<br />Deep Learning.
                                            </h2>
                                            <p className="text-justify text-xs px-5 max-sm:hidden">
                                                {/* STEM education at MH-B inspires innovation, creativity, and problem-solving skills. */}
                                                Kids explore the exciting world of deep learning, sparking imagination and mastering the art of AI innovation.
                                            </p>
                                        </div>
                                        <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black"></div>
                                    </div>
                                </div>
                            </FadeInSectionDownToUp>
                            {/* Large container */}
                            <FadeInSectionDownToUp>
                                <div className="relative bg-gray-800 rounded-xl overflow-hidden h-60 md:h-[400px]">
                                    <img
                                        src={AiAndMl6}
                                        alt="Main Feature 2"
                                        className="object-cover w-full h-full"
                                    />
                                    <div className="absolute inset-0 flex flex-col items-start justify-center bg-black bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">
                                        <h2 className="text-4xl max-sm:text-lg font-bold max-sm:font-normal text-left mt-32 max-sm:mt-32 pl-5 z-50">
                                            Hands-on<br />Projects in AI & ML.
                                        </h2>
                                        <p className="text-justify text-[12px] w-56 mt-2 pl-5 max-sm:hidden">
                                            {/* AR and VR at MH Intellect revolutionize experiences, blending reality with digital innovation for unparalleled learning, training, and interactive adventures. */}
                                            Our program immerses children in hands-on AI and Machine Learning projects, equipping them with the skills to solve real-world challenges. Through practical experience, kids develop critical thinking and creativity, preparing them to become superheroes in the world of AI technology.
                                        </p>
                                    </div>
                                    <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black"></div>
                                </div>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
