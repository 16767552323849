import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import productsInfo from "./ProductInfo";
import { ArrowLeft, ArrowUp } from "lucide-react";

const ProductDetail = () => {

  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const { id } = useParams();
  const product = productsInfo.find((product) => product.id === parseInt(id));
  console.log(product);

  if (!product) {
    return <p>Product not found</p>;
  }


  return (
    <div>
      <section className="flex flex-col overflow-hidden items-center justify-center bg-white dark:bg-black">
        <div className="mt-10 max-sm:mt-5 max-sm:mx-5">
          <img
            src={product.productImage}
            alt={`${product.title}-img`}
            className="rounded-3xl"
            width="600"
          />
        </div>
        <div className="container mx-auto max-w-4xl px-5 py-10 sm:py-20">
          <div className="flex flex-col items-center justify-center">
            <div className="py-10">
              <h1 className="text-5xl sm:text-6xl max-sm:text-3xl font-bold text-black dark:text-white">{product.title}</h1>
              <p className="text-lg text-black dark:text-white">{product.description}</p>
            </div>
            <div className="py-10">
              <button
                type="button"
                className="text-white bg-gradient-to-r from-violet-600 to-pink-600 hover:bg-gradient-to-r hover:from-pink-800  hover:to-violet-800 focus:outline-none  font-medium rounded-full text-sm px-10 py-3.5 text-center transition-all ease-in-out duration-200 shadow-lg"
              >
                {product.buttonText}
              </button>
            </div>
            <div className="max-w-2xl py-10">
              <p className="text-justify text-black dark:text-white">{product.details}</p>
              <div className="py-20">
                <h1 className="text-5xl font-bold text-center pb-10 text-black dark:text-white">
                  {product.outcomeTitle}
                </h1>
                <p className=" text-black dark:text-white">{product.outcomeDescription}</p>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center">
              <h1 className="text-3xl text-center text-black dark:text-white">
                Why Choose{" "}
                <span className="font-bold">MH Intellect</span>?
              </h1>
              <div className="flex flex-col justify-center items-center max-w-xl py-10">
                {product.features.map((feature, index) => (
                  <div
                    className={`flex justify-center items-center py-10 text-black dark:text-white ${index % 2 === 0 ? "mr-16 sm:mr-40" : "ml-16 sm:ml-40"
                      }`}
                    key={index}
                  >
                    {index % 2 === 0 ? (
                      <>
                        <div className="text-end pe-8 sm:pe-14">
                          <h1 className="font-bold text-xl">
                            {feature.title}
                          </h1>
                          <p>{feature.description}</p>
                        </div>
                        <img
                          src={feature.icon}
                          alt={`${feature.title}-img`}
                          className="w-14 sm:w-20"
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src={feature.icon}
                          alt={`${feature.title}-img`}
                          className="w-14 sm:w-20"
                        />
                        <div className="text-start ps-8 sm:ps-14">
                          <h1 className="font-bold text-xl">
                            {feature.title}
                          </h1>
                          <p>{feature.description}</p>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
              <p className="py-10 text-black dark:text-white">{product.conclusionText}</p>
            </div>
          </div>
        </div>
      </section>
      <div className="flex justify-start items-start max-sm:pb-0 xl:sticky xl:bottom-20 pl-5 sm:sticky sm:bottom-20 text-black dark:text-white">
        <button onClick={() => navigate('/products')} className="flex xl:absolute sm:absolute"><ArrowLeft />Back to Products</button>
      </div>
      {/* <div className="flex justify-end items-end max-sm:pb-10 sticky bottom-14 max-sm:bottom-0 pr-5">
        <button className="flex xl:absolute sm:absolute bg-slate-600 bg-opacity-40 px-1 py-1 rounded-xl"><ArrowUp /></button>
      </div> */}
      <div className="flex justify-end items-end max-sm:pb-10 sticky bottom-14 max-sm:bottom-0 pr-4 text-black dark:text-white">
        {isVisible && (
          <button
            onClick={scrollToTop}
            className="flex xl:absolute sm:absolute bg-slate-600 dark:bg-slate-500 bg-opacity-40 px-1 py-1 rounded-xl"
          >
            <ArrowUp />
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductDetail;
