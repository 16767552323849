import React from "react";
import FadeInSectionDownToUp from "../../Components/FadeAnimations/FadeInSectionDownToUp.js"

const valuesData = [
  {
    id: 1,
    title: "Innovation",
    description: "We believe in fostering a culture of creativity and innovation. Our programs are designed to encourage students to think outside the box, experiment with new ideas, and develop ground breaking solutions.",
    imgSrc: "/innovation.jpg",
  },
  {
    id: 2,
    title: "Excellence",
    description: "We are committed to providing the highest quality education and training. Our curriculum is meticulously crafted to ensure students receive a comprehensive and in-depth understanding of the subjects they study.",
    imgSrc: "/excedlllence.jpg",
  },
  {
    id: 3,
    title: "Integrity",
    description: "We uphold the highest standards of integrity and transparency in all our endeavors. Our actions are guided by honesty, ethical principles, and a commitment to doing what is right.",
    imgSrc: "/Integrity.jpg",
  },
  {
    id: 4,
    title: "Collaboration",
    description: "We believe in the power of collaboration and teamwork. By working together with educators, industry experts and the community, we aim to create a supportive and enriching learning experience.",
    imgSrc: "/colloboration.jpg",
  },
  {
    id: 5,
    title: "Inclusivity",
    description: "We are dedicated to creating an inclusive leaming environment where every student feels valued and empowered. We embrace diversity and strive to provide equal opportunities for all students to succeed.",
    imgSrc: "/incl.jpg",
  },
  {
    id: 6,
    title: "Sustainability",
    description: "We are committed to promoting sustainability and environmental responsibility. Our programs emphasize the importance of developing technologies that contribute to a sustainable and equitable world.",
    imgSrc: "/sust.jpg",
  },
];

const Values = () => {
  return (
    <section className="flex justify-center items-center bg-white dark:bg-black">
      <div className="container mx-auto max-w-4xl px-5 py-20">
        <h1 className="text-7xl max-sm:text-5xl font-semibold text-center mb-10 sm:mb-20 text-black dark:text-white">
          <FadeInSectionDownToUp>
            Our <span className="tracking-normal text-transparent bg-gradient-to-b from-pink-600 to-violet-600 bg-clip-text " >Values.</span>
          </FadeInSectionDownToUp>
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-y-10 lg:gap-y-20 gap-x-5 place-items-center">
          {valuesData.map((value) => (
            <div key={value.id} className="max-w-64 bg-white dark:bg-gray-400 dark:bg-opacity-50 dark:border-none border border-gray-200 rounded-3xl shadow-xl overflow-hidden" >
              {/* <a href="#" >  */}
              <img className="rounded-t-3xl max-h-40 " src={value.imgSrc} alt={value.title} />
              {/* </a> */}
              <FadeInSectionDownToUp>
                <div className="p-5 min-h-80 ">
                  {/* <a href="#"> */}
                  <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                    {value.title}
                  </h5>
                  {/* </a> */}
                  <p className="mb-3 font-normal text-gray-700 dark:text-white text-justify">
                    {value.description}
                  </p>
                </div>
              </FadeInSectionDownToUp>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Values;
