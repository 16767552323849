import React from 'react'
import AviationAndAerospace1 from '../../../assets/Projects/Aviation & Aerospace/Avi - RC Plane.jpg';
import AviationAndAerospace2 from '../../../assets/Projects/Aviation & Aerospace/Avi - Cansat.jpg';
import AviationAndAerospace3 from '../../../assets/Projects/Aviation & Aerospace/Avi - Flapping Wing UAV.jpg';
import AviationAndAerospace4 from '../../../assets/Projects/Aviation & Aerospace/Avi - Glider.jpg';
import AviationAndAerospace5 from '../../../assets/Projects/Aviation & Aerospace/Avi - Drones.jpg';
import AviationAndAerospace6 from '../../../assets/Projects/Aviation & Aerospace/Avi - CAD Aeromodeling.jpg';
import FadeInSectionDownToUp from '../../FadeAnimations/FadeInSectionDownToUp';

export default function AviationAndAerospace() {
    return (
        <div>
            <div className="min-h-screen bg-white dark:bg-black p-5">
                <div className="pl-96 xl:pl-96 lg:pl-36 max-sm:pl-0 bg-white dark:bg-black pt-20">
                    <div className="text-start max-sm:text-start mx-auto">
                        <FadeInSectionDownToUp>
                            <h1 className="from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent font-bold text-5xl md:text-6xl lg:text-7xl mr-0 lg:mr-[170px]">
                                Aviation<br />& Aerospace.
                            </h1>
                        </FadeInSectionDownToUp>
                        {/* <h2 className="text-white font-bold text-5xl md:text-6xl lg:text-7xl">
                            
                        </h2> */}
                        {/* <p className="text-gray-400 mt-4 text-lg md:text-xl lg:text-2xl max-w-3xl mx-auto w-full sm:w-[500px] ml-0 lg:ml-[50px] text-justify">
                            Our aviation and aerospace projects focus on model aircraft design, prototyping, and integrating propulsion and control systems, alongside detailed flight testing and maintenance.
                        </p> */}
                    </div>
                </div>
                <div className='flex justify-center items-center max-w-[700px] mx-auto py-10'>
                    <FadeInSectionDownToUp>
                        <p className="text-gray-600 dark:text-gray-400 mt-4 max-sm:mt-10 text-lg md:text-xl lg:text-2xl w-full text-justify">
                            Join our Aviation & Aerospace adventure by heading to the skies! Master the science of flying, create and pilot your own aircraft, and unleash your creativity. It's time for a thrilling voyage where imagination and the sky collide!
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className="max-w-[700px] mx-auto">
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                        {/* Column 1 */}
                        <div className="flex flex-col space-y-4">
                            {/* Large container */}
                            <FadeInSectionDownToUp>
                                <div className="relative bg-gray-800 rounded-xl overflow-hidden h-60 md:h-[400px]">
                                    <img
                                        src={AviationAndAerospace1}
                                        alt="Main Feature 1"
                                        className="object-cover w-full h-full"
                                    />
                                    <div className="absolute inset-0  flex flex-col  bg-black bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500 ">

                                        <h2 className="text-4xl max-sm:text-lg font-bold max-sm:font-normal text-left mt-64 max-sm:mt-48 pl-5 max-sm:pl-5 [word-spacing:-4px] z-50 ">
                                            RC Plane.
                                        </h2>
                                        <p className="text-justify text-[12px] w-56 mt-2  pl-5 max-sm:hidden">
                                            {/* Aeromodelling involves designing, building, and flying miniature aircraft models to understand and apply the principles of aerodynamics and aviation engineering. */}
                                            Reach the sky with our RC Planes while you get to control your own aerial experiences.
                                        </p>

                                    </div>
                                    <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black"></div>
                                </div>
                            </FadeInSectionDownToUp>
                            {/* Small containers */}
                            <FadeInSectionDownToUp>
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="relative bg-gray-800 rounded-xl overflow-hidden h-40 md:h-[200px]">
                                        <img
                                            src={AviationAndAerospace2}
                                            alt="Feature 1"
                                            className="object-cover w-full h-full"
                                        />
                                        <div className="absolute inset-0 flex flex-col items-start justify-center bg-black bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">
                                            <h2 className="text-xl max-sm:text-lg font-bold max-sm:font-normal text-left mt-12 max-sm:mt-24 pl-3 [word-spacing:2px] z-50 ">
                                                Can SAT.
                                            </h2>
                                            <p className="text-justify text-xs px-3 max-sm:hidden">
                                                {/* STEM education at MH-B inspires innovation, creativity, and problem-solving skills. */}
                                                Launch your own mini-satellite with CanSat, exploring space science from your backyard.
                                            </p>
                                        </div>
                                        <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black"></div>
                                    </div>
                                    <div className="relative bg-gray-800 rounded-xl overflow-hidden h-40 md:h-[200px]">
                                        <img
                                            src={AviationAndAerospace3}
                                            alt="Feature 2"
                                            className="object-cover w-full h-full"
                                        />
                                        <div className="absolute inset-0 flex flex-col items-start justify-center bg-black bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">
                                            <h2 className="text-xl font-bold max-sm:font-normal text-left mt-12 max-sm:mt-24 pl-3 [word-spacing:2px] z-50 ">
                                                Flapping Wing UAV.
                                            </h2>
                                            <p className="text-justify text-xs px-3 max-sm:hidden">
                                                {/* STEM education at MH-B inspires innovation, creativity, and problem-solving skills. */}
                                                Fly like birds in the skies with flapping wing UAVs and discover the enchantment of nature.
                                            </p>
                                        </div>
                                        <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black"></div>
                                    </div>
                                </div>
                            </FadeInSectionDownToUp>
                        </div>

                        {/* Column 2 */}
                        <div className="flex flex-col space-y-4">
                            {/* Small containers */}
                            <FadeInSectionDownToUp>
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="relative bg-gray-800 rounded-xl overflow-hidden h-40 md:h-[200px]">
                                        <img
                                            src={AviationAndAerospace4}
                                            alt="Feature 3"
                                            className="object-cover w-full h-full"
                                        />
                                        <div className="absolute inset-0 flex flex-col items-start justify-center bg-black bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">
                                            <h2 className="text-xl font-bold max-sm:font-normal text-left mt-12 max-sm:mt-24 pl-3 [word-spacing:2px] z-50">
                                                Glider.
                                            </h2>
                                            <p className="text-justify text-xs px-3 max-sm:hidden">
                                                {/* STEM education at MH-B inspires innovation, creativity, and problem-solving skills. */}
                                                Master the art of flying with an unpowered aircraft.
                                            </p>
                                        </div>
                                        <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black"></div>
                                    </div>
                                    <div className="relative bg-gray-800 rounded-xl overflow-hidden h-40 md:h-[200px]">
                                        <img
                                            src={AviationAndAerospace5}
                                            alt="Feature 4"
                                            className="object-cover w-full h-full"
                                        />
                                        <div className="absolute inset-0 flex flex-col items-start justify-center bg-black bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">
                                            <h2 className="text-xl font-bold max-sm:font-normal text-left mt-8 max-sm:mt-24 pl-3 [word-spacing:2px] z-50">
                                                Drone Technology.
                                            </h2>
                                            <p className="text-justify px-3 text-xs max-sm:hidden">
                                                {/* STEM education at MH-B inspires innovation, creativity, and problem-solving skills. */}
                                                With drone technology, you can operate the most amazing aerial vehicles and reach new heights.
                                            </p>
                                        </div>
                                        <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black"></div>
                                    </div>
                                </div>
                            </FadeInSectionDownToUp>
                            {/* Large container */}
                            <FadeInSectionDownToUp>
                                <div className="relative bg-gray-800 rounded-xl overflow-hidden h-60 md:h-[400px]">
                                    <img
                                        src={AviationAndAerospace6}
                                        alt="Main Feature 2"
                                        className="object-cover w-full h-full"
                                    />
                                    <div className="absolute inset-0 flex flex-col items-start justify-center bg-black bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">
                                        <h2 className="text-4xl max-sm:text-lg font-bold max-sm:font-normal text-left mt-48 max-sm:mt-32 pl-3 [word-spacing:-4px] z-50 ">
                                            CAD<br />Aeromodeling.
                                        </h2>
                                        <p className="text-justify text-[12px] w-56 mt-2 pl-3 max-sm:hidden">
                                            Create an amazing flying machines of your dreams on your computer by using CAD aeromodeling to design it.
                                        </p>
                                    </div>
                                    <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black"></div>
                                </div>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
