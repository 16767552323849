import React from 'react'
import EducationCover from '../../assets/Education/COVER.jpg'
import { useNavigate } from 'react-router-dom'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';
import SchoolPrograms from '../../Components/School Programs/SchoolPrograms';

export default function Education() {

  const navigate = useNavigate();

  return (
    <div className='bg-white dark:bg-black'>
      <div className='bg-white dark:bg-black'>
        <div className='relative'>
          <div className='relative'>
            <img src={EducationCover} alt='education' />
          </div>
          <div className='absolute bottom-10 xl:bottom-10 max-sm:bottom-1 left-1/3 xl:left-96 max-sm:left-14 max-sm:right-14 sm:left-1/4 sm:right-1/4'>
            <FadeInSectionDownToUp>
              <h1 className='text-8xl xl:text-9xl lg:text-6xl max-sm:text-6xl sm:text-6xl py-10 xl:py-10 max-sm:py-2 text-center leading-[80px] from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent'>
                Education.
              </h1>
            </FadeInSectionDownToUp>
            <FadeInSectionDownToUp>
              <p className='text-center text-white text-xl xl:text-xl max-sm:text-sm tracking-[5px] xl:tracking-[5px] max-sm:tracking-widest'>
                GROW THROUGH WHAT YOU GO THROUGH
              </p>
            </FadeInSectionDownToUp>
          </div>
        </div>
        <div className='pt-20 px-52 xl:px-52 sm:px-10 max-sm:px-10 text-black dark:text-white text-center'>
          <FadeInSectionDownToUp>
            <p className='text-lg text-justify'>
              {/* Dive into hands-on STEM adventures designed to fuel young minds with creativity and problem-solving skills. Empower the next generation with cutting-edge tools and real-world projects that make learning exciting and impactful. Equip students with the knowledge and confidence to thrive in tomorrow’s tech-driven world through dynamic and interactive STEM education. */}
              Discover a world of innovation with our interactive STEM projects designed to ignite creativity and problem-solving skills in young learners. Our hands-on adventures provide students with cutting-edge tools and real-world challenges, transforming learning into an exciting journey. Equip the next generation with the knowledge, skills, and confidence to excel in a technology-driven future through engaging and impactful STEM education.
            </p>
          </FadeInSectionDownToUp>
          {/* <FadeInSectionDownToUp>
            <div className='flex justify-center items-center space-x-5 py-20'>
              <button onClick={() => navigate('/school-programs')} className='bg-gradient-to-b from-pink-500 to-pink-700 hover:bg-gradient-to-b hover:from-pink-600 hover:to-pink-800 px-14 xl:px-14 max-sm:px-5 py-3 xl:py-3 max-sm:py-1 rounded-3xl text-lg xl:text-lg max-sm:text-sm'>MH Intellect</button>
              <button className='bg-gradient-to-b from-pink-500 to-pink-700 hover:bg-gradient-to-b hover:from-pink-600 hover:to-pink-800 px-14 xl:px-14 max-sm:px-5 py-3 xl:py-3 max-sm:py-1 rounded-3xl text-lg xl:text-lg max-sm:text-sm'>MH Innovation</button>
            </div>
          </FadeInSectionDownToUp> */}
        </div>
      </div>
        <div className=''>
          <SchoolPrograms />
        </div>
    </div>
  )
}
