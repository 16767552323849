const products = [
  // RC Planes Project
  {
    id: 11,
    productImage: "/Product Details Page/Intellect Advanced STEM Kit Box.jpg",
    title: "Advanced STEM Kit",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "MH Intellect Advanced STEM Kit! Perfect for young inventors, this kit includes a Robotic Arm, Hexapod, and WiFi Car, giving you the chance to build, program, and control your own robots.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Learn about robotics, coding, and electronics, giving you a head start in STEM subjects.",
    features: [
      {
        title: "Quality",
        description: "All materials are designed with safety in mind, making it a perfect kit for young learners.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    conclusionText: "Join a community of young inventors who love building and sharing their creations.",
  },
  {
    id: 6,
    productImage: "/Product Details Page/Quadcopter.jpg",
    title: "Quadcopter",
    description: "Advanced Level",
    buttonText: "Expand Your Horizons",
    details: "Designed for advanced pilots, our quadcopters provide superior stability and advanced control. With enhanced features and capabilities, they are ideal for practicing complex maneuvers and capturing high-quality aerial footage.",
    outcomeTitle: "Outcome",
    outcomeDescription: "You'll enhance your control and precision, preparing for advanced flight challenges with a sophisticated drone.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Community",
        description: "Join our passionate drone enthusiasts and share your experiences.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    conclusionText: "MH Intellect drones offer an unmatched flying experience for every skill level. Explore our range and elevate your aerial adventure today!",
  },
  {
    id: 9,
    productImage: "/Product Details Page/Can Sat.jpg",
    title: "CanSat",
    description: "Educational Satellite Model",
    buttonText: "Launch Your Learning Journey",
    details: "The CanSat is a smaller, educational satellite model that simulates the functionality of real satellites. It's perfect for educational purposes, providing students and hobbyists with a practical introduction to satellite technology and space missions.",
    outcomeTitle: "Outcome",
    outcomeDescription: "You'll build a foundational understanding of satellite operations, data collection, and space mission planning, all while engaging in a hands-on learning experience.",
    features: [
      {
        title: "Quality",
        description: "We use advanced materials and technologies to ensure the reliability and performance of our CubeSats and CanSats.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Stay at the forefront of space technology with our cutting-edge satellite platforms and educational tools.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    conclusionText: "Explore MH Intellect's CubeSat and CanSat solutions and take your space exploration journey to new heights today!",
  },
  {
    id: 1,
    productImage: "/Product Details Page/Foam Board RC.jpg",
    title: "Foam Board RC Plane",
    description: "Beginner Level",
    buttonText: "Start Your Journey",
    details: "Learn the basics of RC flying with easy-to-assemble, durable, and stable foam board planes. The lightweight construction is forgiving and ideal for new pilots.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Gain essential flying skills and confidence in operating RC planes, laying the groundwork for advanced remote-controlled aviation pursuits.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Community",
        description: "Join our passionate RC enthusiasts and share your experiences.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    conclusionText: "MH Intellect RC planes offer an unmatched flying experience for every skill level. Explore our range and elevate your RC flying adventure today!",
  },
  {
    id: 12,
    productImage: "/Product Details Page/WiFi Car.jpg",
    title: "Rover Car",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "Exciting adventure with the Rover Car, part of the MH Intellect Advanced STEM Kit! Perfect for young inventors, this kit lets you build, program, and control your own WiFi-enabled rover car, sparking creativity and innovation.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Equip yourself with skills that prepare you for exciting opportunities in technology and engineering.",
    features: [
      {
        title: "Quality",
        description: "All materials are designed with safety in mind, ensuring a safe and enjoyable learning experience.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Experiment with different designs and functions to create a rover that's uniquely yours.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    conclusionText: "Share your projects and get inspired by others in the community.",
  },
  {
    id: 2,
    productImage: "/Product Details Page/RC plane.jpg",
    title: "3D Printed RC Plane",
    description: "Intermediate Level",
    buttonText: "Elevate Your Skills",
    details: "For intermediate pilots, this course features a 3D printed frame for more structural integrity, with realistic designs and responsive controls for skill development.",
    outcomeTitle: "Outcome",
    outcomeDescription: "You'll improve control and maneuvering skills, gaining experience with more powerful and responsive RC planes.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Community",
        description: "Join our passionate RC enthusiasts and share your experiences.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    conclusionText: "MH Intellect RC planes offer an unmatched flying experience for every skill level. Explore our range and elevate your RC flying adventure today!",
  },
  {
    id: 5,
    productImage: "/Product Details Page/Nano Drone.jpg",
    title: "Nano Drone",
    description: "Beginner Level",
    buttonText: "Start Your Aerial Adventure",
    details: "Learn the basics of drone flying with our easy-to-control, compact nano drones. Their lightweight and durable design make them ideal for new pilots, offering a forgiving flight experience as you master the fundamentals.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Develop basic flying skills and gain confidence in handling nano drones, setting the stage for more advanced drone piloting.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Community",
        description: "Join our passionate drone enthusiasts and share your experiences.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    conclusionText: "MH Intellect drones offer an unmatched flying experience for every skill level. Explore our range and elevate your aerial adventure today!",
  },
  {
    id: 3,
    productImage: "/Product Details Page/Avanti S.jpg",
    title: "Fighter EDF RC Plane",
    description: "Advanced Level",
    buttonText: "Master the Skies",
    details: "Designed for advanced pilots, this course covers high-speed performance and precision, with models built for aerobatics and complex maneuvers.",
    outcomeTitle: "Outcome",
    outcomeDescription: "You'll achieve advanced flying techniques, perform complex aerobatic stunts, and enjoy high-speed, precise control.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Community",
        description: "Join our passionate RC enthusiasts and share your experiences.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    conclusionText: "MH Intellect RC planes offer an unmatched flying experience for every skill level. Explore our range and elevate your RC flying adventure today!",
  },

  // Gliders Project
  {
    id: 4,
    productImage: "/Product Details Page/Glider.jpg",
    title: "Glider",
    description: "",
    buttonText: "Experience the Art of Gliding",
    details: "Discover the thrill of silent flight with MH Intellect gliders. Our gliders are designed for stability, efficiency, and performance, providing an exceptional flying experience.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Achieve a deep understanding of gliding principles, master basic to advanced gliding techniques, and develop the skills to perform efficient and controlled flights. Join a community of passionate gliding enthusiasts.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure optimal performance and durability.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team provides advice and guidance to help you succeed.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Community",
        description: "Join our passionate gliding enthusiasts and share your experiences.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    conclusionText: "Elevate your gliding experience with MH Intellect. Explore our range of gliders and take flight today!",
  },
  {
    id: 7,
    productImage: "/Product Details Page/Hexacopter.jpg",
    title: "Hexacopter",
    description: "Expert Level",
    buttonText: "Command the Skies",
    details: "For experienced pilots seeking top performance, our hexacopters offer exceptional stability and power with six rotors. Built for high-speed flights and advanced maneuvers, they provide a professional-grade flying experience.",
    outcomeTitle: "Outcome",
    outcomeDescription: "You'll master advanced flying techniques, perform precise maneuvers, and enjoy superior control in challenging conditions.",
    features: [
      {
        title: "Quality",
        description: "Superior materials and innovative designs ensure top performance and durability.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Expert advice and assistance are always available to help you succeed.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Community",
        description: "Join our passionate drone enthusiasts and share your experiences.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    conclusionText: "MH Intellect drones offer an unmatched flying experience for every skill level. Explore our range and elevate your aerial adventure today!",
  },

  // Space Projects
  {
    id: 8,
    productImage: "/Product Details Page/Cube SAT.jpg",
    title: "CubeSat",
    description: "Advanced Satellite Platform",
    buttonText: "Unlock Space Exploration",
    details: " Our CubeSats provide a modular and versatile platform for a wide range of space missions. Designed to meet rigorous standards, these small satellites offer high-performance capabilities in a compact form factor. Ideal for scientific research, technology demonstration, and Earth observation",
    outcomeTitle: "Outcome",
    outcomeDescription: " You'll gain hands-on experience with space technology, conduct experiments in orbit, and develop skills in satellite design and operations.",
    features: [
      {
        title: "Quality",
        description: "We use advanced materials and technologies to ensure the reliability and performance of our CubeSats and CanSats.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Stay at the forefront of space technology with our cutting-edge satellite platforms and educational tools.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    conclusionText: "Explore MH Intellect's CubeSat and CanSat solutions and take your space exploration journey to new heights today!",
  },
  {
    id: 10,
    productImage: "/Product Details Page/Humanoid.jpg",
    title: "Humanoid Robot",
    description: "",
    buttonText: "Launch Your Learning Journey",
    details: "The Humanoid Kit, where creativity meets technology! This hands-on kit allows kids to build and program a humanoid robot that can walk and dance, introducing them to the exciting world of robotics, coding and AI.",
    outcomeTitle: "Outcome",
    outcomeDescription: "Develop critical thinking and troubleshooting abilities through hands-on robot assembly and coding challenges.",
    features: [
      {
        title: "Quality",
        description: "Each kit includes durable and reliable parts designed to withstand repeated use and experimentation.",
        icon: "/Product Details Page/Icon 01.png",
      },
      {
        title: "Support",
        description: "Our expert team offers comprehensive guidance and support throughout your project, from design to launch.",
        icon: "/Product Details Page/Icon 02.png",
      },
      {
        title: "Innovation",
        description: "Provides opportunities to explore artificial intelligence concepts, enabling robots to learn and adapt to new tasks.",
        icon: "/Product Details Page/Icon 03.png",
      },
    ],
    conclusionText: "Inspire innovation and creativity with the Humanoid Kit, preparing young minds to become pioneers in technology and robotics.",
  },

];

export default products;
