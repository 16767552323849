import { useEffect } from 'react';
import metaDescriptions from './metaDescriptions'; // Import the mapping

const MetaDescription = ({ pageLink }) => {
  useEffect(() => {
    const { description = "Default meta description", keywords = "default, keywords" } = metaDescriptions[pageLink] || {};

    // Update or create meta description tag
    const metaDescriptionTag = document.querySelector('meta[name="description"]');
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute('content', description);
    } else {
      const newMetaDescriptionTag = document.createElement('meta');
      newMetaDescriptionTag.setAttribute('name', 'description');
      newMetaDescriptionTag.setAttribute('content', description);
      document.head.appendChild(newMetaDescriptionTag);
    }

    // Update or create meta keywords tag
    const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
    if (metaKeywordsTag) {
      metaKeywordsTag.setAttribute('content', keywords);
    } else {
      const newMetaKeywordsTag = document.createElement('meta');
      newMetaKeywordsTag.setAttribute('name', 'keywords');
      newMetaKeywordsTag.setAttribute('content', keywords);
      document.head.appendChild(newMetaKeywordsTag);
    }
  }, [pageLink]);

  return null;
};

export default MetaDescription;
