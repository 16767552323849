import React from 'react'
import StemAndRoboticsARandVR from '../../../assets/Aviation And Aerospace Lab/S&R - VR & AR T.jpg'
import StemAndRobotics2 from '../../../assets/Aviation And Aerospace Lab/S&R - IOT  S3.jpg'
import StemAndRobotics3 from '../../../assets/Aviation And Aerospace Lab/S&R -DIY  S4.jpg'
import StemAndRobotics4 from '../../../assets/Aviation And Aerospace Lab/S&R - R S1.jpg'
import StemAndRobotics5 from '../../../assets/Aviation And Aerospace Lab/S&R - Python S2.jpg'
import StemAndRobotics6 from '../../../assets/Aviation And Aerospace Lab/S&R - AR.jpg'
import FadeInSectionDownToUp from '../../FadeAnimations/FadeInSectionDownToUp'

export default function StemAndRobotics() {
    return (
        <div>
            <div className="min-h-screen bg-white dark:bg-black p-5">
                <div className="pl-96 xl:pl-96 lg:pl-36 max-sm:pl-0 bg-white dark:bg-black pt-20">
                    <div className="text-start max-sm:text-start mx-auto">
                        <FadeInSectionDownToUp>
                            <h1 className="from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent font-bold text-5xl md:text-6xl lg:text-7xl">
                                STEM<br />& Robotics.
                            </h1>
                            {/* <h2 className="from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent font-bold text-5xl md:text-6xl lg:text-7xl">
                                
                            </h2> */}
                        </FadeInSectionDownToUp>
                    </div>
                </div>
                <div className='flex justify-center items-center max-w-[700px] mx-auto py-10'>
                    <FadeInSectionDownToUp>
                        <p className="text-gray-600 dark:text-gray-400 mt-4 max-sm:mt-10 text-lg md:text-xl lg:text-2xl w-full text-justify">
                            {/* Discover the dynamic realm of STEM and robotics, where science, technology, engineering, and mathematics unite to craft innovative solutions. */}
                            Welcome to the thrilling world of robotics. Here, science, technology, engineering, and math unite to transform your young ones into a superhero of innovation. Join us in inspiring and empowering the next generation of thinkers, creators, and leaders.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className="max-w-[700px] mx-auto">
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                        {/* Column 1 */}
                        <div className="flex flex-col space-y-4">
                            {/* Large container */}
                            <FadeInSectionDownToUp>
                                {/* <div className="relative bg-gray-800 rounded-xl overflow-hidden h-60 md:h-[400px]">
                                    <img
                                        src={StemAndRoboticsARandVR}
                                        alt="Main Feature 1"
                                        className="object-cover w-full h-full"
                                    />
                                    <div className="absolute inset-0  flex flex-col  pl-10  bg-black bg-opacity-50 xl:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500 ">

                                        <h2 className="text-4xl font-bold text-left mt-60 max-sm:mt-40  [word-spacing:-4px]">AR & VR Tech.</h2>
                                        <p className="block text-left text-[12px] w-56 mt-2 max-sm:hidden">
                                            AR and VR at MH Intellect revolutionize experiences, blending reality with digital innovation for unparalleled learning, training, and interactive adventures.
                                        </p>

                                    </div>
                                </div> */}
                                <div className="relative bg-gray-800 rounded-xl overflow-hidden h-60 md:h-[400px]">
                                    <img
                                        src={StemAndRoboticsARandVR}
                                        alt="Main Feature 1"
                                        className="object-cover w-full h-full"
                                    />
                                    <div className="absolute inset-0 flex flex-col pl-10 xl:pl-5 max-sm:pl-5 bg-black bg-opacity-50 xl:bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">

                                        <h2 className="font-normal text-left mt-52 max-sm:mt-44 max-sm:z-50">
                                            <span className='block max-sm:hidden text-4xl [word-spacing:-4px]'>
                                                AR & VR Tech.
                                            </span>
                                            <span className='hidden max-sm:block text-lg'>
                                                AR & VR Technology.
                                            </span>
                                        </h2>
                                        <p className="block text-justify text-[12px] w-56 mt-2 max-sm:hidden">
                                            {/* AR and VR at MH Intellect revolutionize experiences, blending reality with digital innovation for unparalleled learning, training, and interactive adventures. */}
                                            With AR and VR technology, your child will get to explore the solar system, go on a virtual tour of ancient rome and deep dive into the ocean- All while being in the classroom. Thus making their learning process more fascinating and memorable.
                                        </p>

                                    </div>
                                    <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black"></div>
                                </div>
                            </FadeInSectionDownToUp>
                            {/* Small containers */}
                            <FadeInSectionDownToUp>
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="relative bg-gray-800 rounded-xl overflow-hidden h-40 md:h-[200px]">
                                        <img
                                            src={StemAndRobotics2}
                                            alt="Feature 1"
                                            className="object-cover w-full h-full"
                                        />
                                        <div className="absolute inset-0 flex flex-col items-start justify-center bg-black bg-opacity-50 xl:bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">
                                            <h2 className="font-bold max-sm:font-normal text-left pl-3 max-sm:mt-20 max-sm:z-50">
                                                <span className='block max-sm:hidden text-xl'>
                                                    IOT.
                                                </span>
                                                <span className='hidden max-sm:block text-lg'>
                                                    IOT.
                                                </span>
                                            </h2>
                                            <p className="text-justify px-3 text-xs max-sm:hidden">
                                                {/* STEM education at MH-B inspires innovation, creativity, and problem-solving skills. */}
                                                Through practical projects that educate children how smart devices interact, our IoT program at MH Intellect fosters creativity and problem-solving skills.
                                            </p>
                                        </div>
                                        <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black/75"></div>
                                    </div>
                                    <div className="relative bg-gray-800 rounded-xl overflow-hidden h-40 md:h-[200px]">
                                        <img
                                            src={StemAndRobotics3}
                                            alt="Feature 2"
                                            className="object-cover w-full h-full"
                                        />
                                        <div className="absolute inset-0 flex flex-col items-start justify-center bg-black bg-opacity-50 xl:bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">
                                            <h2 className="font-bold max-sm:font-normal text-left pl-3 max-sm:mt-20 max-sm:z-50">
                                                <span className='block max-sm:hidden text-xl [word-spacing:2px]'>
                                                    DIY - Projects.
                                                </span>
                                                <span className='hidden max-sm:block text-lg'>
                                                    DIY Projects.
                                                </span>
                                            </h2>
                                            <p className="text-justify px-3 text-xs max-sm:hidden">
                                                {/* STEM education at MH-B inspires innovation, creativity, and problem-solving skills. */}
                                                Kids can develop their problem-solving abilities and explore their creativity by building and designing their own original creations through do-it-yourself projects.
                                            </p>
                                            <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black/75"></div>
                                        </div>
                                    </div>
                                </div>
                            </FadeInSectionDownToUp>
                        </div>

                        {/* Column 2 */}
                        <div className="flex flex-col space-y-4">
                            {/* Small containers */}
                            <FadeInSectionDownToUp>
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="relative bg-gray-800 rounded-xl overflow-hidden h-40 md:h-[200px]">
                                        <img
                                            src={StemAndRobotics4}
                                            alt="Feature 3"
                                            className="object-cover w-full h-full"
                                        />
                                        <div className="absolute inset-0 flex flex-col items-left justify-center bg-black bg-opacity-50 xl:bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">
                                            <h2 className="font-bold max-sm:font-normal text-left max-sm:mt-20 pl-5 z-50">
                                                <span className='block max-sm:hidden text-xl [word-spacing:2px]'>
                                                    Robotics.
                                                </span>
                                                <span className='hidden max-sm:block text-lg'>
                                                    Robotics.
                                                </span>
                                            </h2>
                                            <p className="text-justify px-5 text-xs max-sm:hidden">
                                                {/* STEM education at MH-B inspires innovation, creativity, and problem-solving skills. */}
                                                Students' creativity and problem-solving abilities are developed through practical projects in our robotics program at MH Intellect, which encourages them to investigate technology and engineering.
                                            </p>
                                        </div>
                                        <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black/75"></div>
                                    </div>
                                    <div className="relative bg-gray-800 rounded-xl overflow-hidden h-40 md:h-[200px]">
                                        <img
                                            src={StemAndRobotics5}
                                            alt="Feature 4"
                                            className="object-cover w-full h-full"
                                        />
                                        <div className="absolute inset-0 flex flex-col items-start justify-center bg-black bg-opacity-50 xl:bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">
                                            <h2 className="font-bold max-sm:font-normal text-left max-sm:mt-20 pl-5 xl:pl-3 z-50">
                                                <span className='block max-sm:hidden text-xl [word-spacing:2px]'>
                                                    Coding.
                                                </span>
                                                <span className='hidden max-sm:block text-lg'>
                                                    Coding.
                                                </span>
                                            </h2>
                                            <p className="px-3 text-xs text-justify max-sm:hidden">
                                                {/* STEM education at MH-B inspires innovation, creativity, and problem-solving skills. */}
                                                Our coding curriculum prepares children for success in a tech-driven future by teaching them to design apps and solve real-world problems, fostering critical thinking and digital skills.
                                            </p>
                                        </div>
                                        <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black/75"></div>
                                    </div>
                                </div>
                            </FadeInSectionDownToUp>
                            {/* Large container */}
                            <FadeInSectionDownToUp>
                                <div className="relative bg-gray-800 rounded-xl overflow-hidden h-60 md:h-[400px]">
                                    <img
                                        src={StemAndRobotics6}
                                        alt="Main Feature 2"
                                        className="object-cover w-full h-full"
                                    />
                                    <div className="absolute inset-0 flex flex-col items-start justify-center bg-black bg-opacity-50 xl:bg-opacity-50 sm:bg-opacity-50 max-sm:bg-opacity-0 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity duration-500">
                                        <h2 className="font-bold max-sm:font-normal text-left mt-48 max-sm:mt-44 pl-5 z-50">
                                            <span className='block max-sm:hidden text-4xl [word-spacing:-4px]'>
                                                Advanced Robotics.
                                            </span>
                                            <span className='hidden max-sm:block text-lg'>
                                                Advanced Robotics.
                                            </span>
                                        </h2>
                                        <p className="text-justify text-[12px] w-56 mt-2 pl-5 max-sm:hidden">
                                            {/* AR and VR at MH Intellect revolutionize experiences, blending reality with digital innovation for unparalleled learning, training, and interactive adventures. */}
                                            Advanced robotics program empowers students to innovate and problem-solve by designing and building their own robotic systems, inspiring the engineers of tomorrow.
                                        </p>
                                    </div>
                                    <div className="hidden max-sm:block absolute inset-0 pointer-events-none before:content-[''] before:absolute before:inset-0 before:rounded-xl before:bg-gradient-to-bl before:from-transparent before:to-black"></div>
                                </div>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
