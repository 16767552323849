// const metaDescriptions = {
//     '/': 'MH Intellect',
//     '/products': 'product page'
//   };
  
//   export default metaDescriptions;
  


// metaDescriptions.js
const metaDescriptions = {
    "/": {
      description: "Welcome to the home page. Discover our latest updates.",
      keywords: "home, main page, updates, news"
    },
    "/products": {
      description: "Learn more about us and our mission."
    },
    contact: {
      description: "Get in touch with us for any inquiries.",
      keywords: "contact, support, inquiries"
    },
    // Add more pages as needed
  };
  
  export default metaDescriptions;
  