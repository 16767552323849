import React, { useState } from "react";
import { Link } from "react-router-dom";
import productsInfo from "../../Components/Product/ProductInfo.js";
import FadeInSectionDownToUp from "../../Components/FadeAnimations/FadeInSectionDownToUp.js";
import MetaDescription from "../../Components/Meta Description/MetaDescription.js";

const ProductsList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(productsInfo);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = productsInfo.filter((product) =>
      product.title.toLowerCase().includes(value)
    );
    setFilteredProducts(filtered);
  };

  // Meta Description

  const currentPageLink = window.location.pathname;

  return (
    <div className="flex flex-col  justify-center items-center py-20 px-5 mx-auto bg-white dark:bg-black">

      {/* Meta Description */}

      <MetaDescription pageLink={currentPageLink} />

      <FadeInSectionDownToUp>
        <h1 className="text-5xl font-bold text-center pb-10">
          <span className="bg-gradient-to-r from-violet-600 to-pink-600 tracking-normal leading-tight text-transparent bg-clip-text">
            Our{" "}
            Products
          </span>
        </h1>
      </FadeInSectionDownToUp>
      <div className="mb-10 relative max-w-5xl">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          className="w- max-sm:w-72 px-4 py-2 border-2 border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-violet-600"
          placeholder="Search for a product..."
        />
        {searchTerm && (
          <div className="absolute top-14 left-0 z-50">
            <ul className="bg-white border-2 border-gray-300 rounded-lg shadow-lg w-full">
              {filteredProducts.map((product) => (
                <li key={product.id} className="px-4 py-2 hover:bg-gray-200">
                  <Link to={`/product-detail/${product.id}`}>
                    {product.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="grid grid-cols-4 xl:grid-cols-4 sm:grid-cols-1 max-sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 px-20 xl:px-20 sm:px-0 max-sm:px-0 gap-10 max-sm:gap-2 place-items-center">
        {filteredProducts.map((product) => (
          <div
            key={product.id}
            className="product-card  w-full m-5 bg-white rounded-3xl hover:shadow-xl shadow-lg overflow-hidden transform transition duration-500 hover:scale-105"
          >
            <Link to={`/product-detail/${product.id}`} className="block">
              <img
                src={product.productImage}
                alt={product.title}
                className="w-full h-48 object-cover bg-gray-500"
              />
              <div className="p-5">
                <h2 className="text-lg text-center font-semibold text-gray-800 mb-2">
                  {product.title}
                </h2>
                {/* <p className="text-sm text-gray-600">{product.description}</p> */}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsList;
