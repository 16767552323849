import React from 'react'

export default function Footer() {
    return (
        <div className='bg-black py-2 border-t border-white'>
            <div className='text-center text-lg text-white'>
                <p>Copyright © {new Date().getFullYear()} MH Intellect. All rights reserved.</p>
            </div>
        </div>
    )
}
