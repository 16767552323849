import React, { useState } from "react";
import { FaFacebookF, FaInstagram, FaWhatsapp, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import Home from "./Pages/Home/Home";
import Navbar from "./Components/Header/Navbar";
import ScrollToTop from "./ScrollToTop";
import { Navigate, Route, Routes } from "react-router-dom";
import Education from "./Pages/Education/Education";
import SchoolPrograms from "./Components/School Programs/SchoolPrograms";
import Projects from "./Pages/Projects/Projects";
import Lab from "./Pages/Lab/Lab";
import About from "./Pages/About/About";
import Values from "./Pages/Values/Values";
import VissionAndMission from "./Pages/Vission & Mission/VissionAndMission";
import ContactUs from "./Pages/Contact Us/ContactUs";
import Gallery from "./Pages/Gallery/Gallery";
import AIandMLLAB from "./Components/Ai&Ml_Lab/AMLab";
import SRLab from "./Components/Stem&RoboticsLab/SRLab";
import Footer from "./Components/Footer/Footer";
import AviAndAeroLab from "./Components/Aviation & Aero Lab/New/AviAndAeroLab";
import ProductsList from "./Pages/Products/ProductsList";
import ProductDetail from "./Components/Product/ProductDetail";
import OurGovernance from "./Pages/Our Governance/OurGovernance";
import { BsTwitterX } from "react-icons/bs";

function App() {

  const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);

  return (
    <div>
      <Navbar setBackgroundBlur={setIsBackgroundBlurred} />
      <div id="background" className={`xl:pt-12 max-sm:pt-12 ${isBackgroundBlurred ? '' : ''}`}>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={<Home />}
          />
          <Route
            path="/education"
            element={<Education />}
          />
          <Route
            path="/school-programs"
            element={<SchoolPrograms />}
          />
          <Route
            path="/aviation-and-aero-lab"
            element={<AviAndAeroLab />}
          />
          <Route
            path="/projects"
            element={<Projects />}
          />
          <Route
            path="/stem-and-robotics"
            element={<SRLab />}
          />
          <Route
            path="/ai-and-ml"
            element={<AIandMLLAB />}
          />
          <Route
            path="/lab"
            element={<Lab />}
          />
          <Route
            path="/about-us"
            element={<About />}
          />
          <Route
            path="/our-values"
            element={<Values />}
          />
          <Route
            path="/vission-mission"
            element={<VissionAndMission />}
          />
          <Route
            path="/contact"
            element={<ContactUs />}
          />
          <Route
            path="/products"
            element={<ProductsList />}
          />
          <Route
            path="/product-detail/:id"
            element={<ProductDetail />}
          />
          <Route
            path="/gallery"
            element={<Gallery />}
          />
          <Route
            path="/our-governance"
            element={<OurGovernance />}
          />
          <Route
            path="*"
            element={<Navigate to='/' />}
          />
        </Routes>
        <Footer />

        <div className="fixed top-1/2 right-4 space-y-4 z-50">
          <a
            href="https://www.linkedin.com/company/mh-intellect/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 transition-colors"
          >
            <FaLinkedinIn size={30} className="my-3 bg-black dark:bg-gray-500 bg-opacity-75 p-1 rounded" />
          </a>
          <a
            href="https://x.com/MHIntellect5387"
            target="_blank"
            rel="noopener noreferrer"
            className="text-black transition-colors"
          >
            <BsTwitterX size={30} className="my-3 bg-gray-500 bg-opacity-75 p-1 rounded" />
          </a>
          <a
            href="https://www.instagram.com/mh_intellect/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-pink-500 hover:text-pink-700 transition-colors"
          >
            <FaInstagram size={30} className="my-3 bg-black dark:bg-gray-500 bg-opacity-75 p-1 rounded" />
          </a>
          <a
            href="https://www.youtube.com/@mhintellect"
            target="_blank"
            rel="noopener noreferrer"
            className="text-red-600 hover:text-red-700 transition-colors"
          >
            <FaYoutube size={30} className="my-3 bg-black dark:bg-gray-500 bg-opacity-75 p-1 rounded" />
          </a>
          <a
            href="https://wa.me/966583584111"
            target="_blank"
            rel="noopener noreferrer"
            className="text-green-500 hover:text-green-600 transition-colors"
          >
            <FaWhatsapp size={30} className="my-3 bg-black dark:bg-gray-500 bg-opacity-75 p-1 rounded" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
