import React from 'react'
import StemAndRobotics from '../../Components/Projects/STEM & Robotics/StemAndRobotics'
import AiAndMl from '../../Components/Projects/AI & ML/AiAndMl'
import AviationAndAerospace from '../../Components/Projects/Aviation & Aerospace/AviationAndAerospace'

export default function Projects() {
  return (
    <div>
        <StemAndRobotics />
        <AiAndMl />
        <AviationAndAerospace />
    </div>
  )
}
