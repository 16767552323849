// import React, { useRef, useState } from 'react';
// import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute } from 'react-icons/fa'; // Import icons from react-icons

// const Video_1 = ({ videoSrc, thumbnailSrc, className }) => {
//   const videoRef = useRef(null);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [isMuted, setIsMuted] = useState(false);

//   const togglePlayPause = () => {
//     if (videoRef.current.paused) {
//       videoRef.current.play();
//       setIsPlaying(true);
//     } else {
//       videoRef.current.pause();
//       setIsPlaying(false);
//     }
//   };

//   const toggleMute = () => {
//     videoRef.current.muted = !videoRef.current.muted;
//     setIsMuted(videoRef.current.muted);
//   };

//   const handleProgress = () => {
//     // Custom logic for progress updates can go here
//   };

//   const handleVolumeChange = (e) => {
//     videoRef.current.volume = e.target.value;
//   };

//   return (
//     <div className="relative w-full h-full">
//       <video
//         ref={videoRef}
//         muted={isMuted}
//         autoPlay={false}
//         loop
//         // className="w-full h-full object-cover"
//         onTimeUpdate={handleProgress}
//         poster={thumbnailSrc}
//         className={className}
//       >
//         <source src={videoSrc} type="video/mp4" />
//       </video>
      
//       {/* Controls */}
//       <div className="absolute bottom-0 left-0 w-full flex justify-between p-4">
//         <button
//           onClick={togglePlayPause}
//           className="text-white text-2xl bg-black bg-opacity-50 p-2 rounded-full"
//           aria-label={isPlaying ? 'Pause' : 'Play'}
//         >
//           {isPlaying ? <FaPause /> : <FaPlay />}
//         </button>
        
//         <button
//           onClick={toggleMute}
//           className="text-white text-2xl bg-black bg-opacity-50 p-2 rounded-full"
//           aria-label={isMuted ? 'Unmute' : 'Mute'}
//         >
//           {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
//         </button>
//       </div>
      
//       {/* Optional Volume Slider */}
//       {/* 
//       <div className="absolute bottom-0 left-0 w-full flex items-center p-4 bg-gradient-to-t from-black via-transparent to-transparent">
//         <input
//           type="range"
//           min="0"
//           max="1"
//           step="0.01"
//           defaultValue="1"
//           onChange={handleVolumeChange}
//           className="w-full"
//         />
//       </div>
//       */}
//     </div>
//   );
// };

// export default Video_1;


import React, { useRef, useState, useEffect } from 'react';
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';

const Video1 = ({ videoSrc, thumbnailSrc, className, isPlaying, onPlay }) => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isPlaying]);

  const togglePlayPause = () => {
    if (isPlaying) {
      onPlay(null); // Notify parent to stop playing
    } else {
      onPlay(videoSrc); // Notify parent to play this video
    }
  };

  const toggleMute = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setIsMuted(videoRef.current.muted);
  };

  const handleProgress = () => {
    // Custom logic for progress updates can go here
  };

  return (
    <div className="relative w-full h-full">
      <video
        ref={videoRef}
        muted={isMuted}
        autoPlay={false}
        loop
        onTimeUpdate={handleProgress}
        poster={thumbnailSrc}
        className={className}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
      
      {/* Controls */}
      <div className="absolute bottom-0 left-0 w-full flex justify-between p-4">
        <button
          onClick={togglePlayPause}
          className="text-white text-2xl bg-black bg-opacity-50 p-2 rounded-full"
          aria-label={isPlaying ? 'Pause' : 'Play'}
        >
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        
        <button
          onClick={toggleMute}
          className="text-white text-2xl bg-black bg-opacity-50 p-2 rounded-full"
          aria-label={isMuted ? 'Unmute' : 'Mute'}
        >
          {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
        </button>
      </div>
    </div>
  );
};

export default Video1;




// import React, { useRef, useState } from 'react';
// import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute, FaExpandAlt, FaCompressAlt } from 'react-icons/fa'; // Import icons from react-icons

// const Video_1 = ({ videoSrc, thumbnailSrc, className }) => {
//   const videoRef = useRef(null);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [isMuted, setIsMuted] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const togglePlayPause = () => {
//     if (videoRef.current.paused) {
//       videoRef.current.play();
//       setIsPlaying(true);
//     } else {
//       videoRef.current.pause();
//       setIsPlaying(false);
//     }
//   };

//   const toggleMute = () => {
//     videoRef.current.muted = !videoRef.current.muted;
//     setIsMuted(videoRef.current.muted);
//   };

//   const handleProgress = () => {
//     // Custom logic for progress updates can go here
//   };

//   const handleVolumeChange = (e) => {
//     videoRef.current.volume = e.target.value;
//   };

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <div className="relative w-full h-full">
//       <video
//         ref={videoRef}
//         muted={isMuted}
//         autoPlay={false}
//         loop
//         // className="w-full h-full object-cover"
//         onTimeUpdate={handleProgress}
//         poster={thumbnailSrc}
//         className={className}
//       >
//         <source src={videoSrc} type="video/mp4" />
//       </video>
      
//       {/* Controls */}
//       <div className="absolute bottom-0 left-0 w-full flex justify-between p-4">
//         <button
//           onClick={togglePlayPause}
//           className="text-white text-2xl bg-black bg-opacity-50 p-2 rounded-full"
//           aria-label={isPlaying ? 'Pause' : 'Play'}
//         >
//           {isPlaying ? <FaPause /> : <FaPlay />}
//         </button>
        
//         <button
//           onClick={toggleMute}
//           className="text-white text-2xl bg-black bg-opacity-50 p-2 rounded-full"
//           aria-label={isMuted ? 'Unmute' : 'Mute'}
//         >
//           {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
//         </button>
        
//         <button
//           onClick={openModal}
//           className="text-white text-2xl bg-black bg-opacity-50 p-2 rounded-full"
//           aria-label="Expand"
//         >
//           <FaExpandAlt />
//         </button>
//       </div>
      
//       {/* Modal Dialog */}
//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
//           <div className="relative w-11/12 max-w-3xl bg-white p-4 rounded-lg">
//             <button
//               onClick={closeModal}
//               className="absolute top-2 right-2 text-black text-2xl"
//               aria-label="Close"
//             >
//               <FaCompressAlt />
//             </button>
//             <video
//               ref={videoRef}
//               muted={isMuted}
//               autoPlay
//               loop
//               className="w-full h-full object-cover"
//               onTimeUpdate={handleProgress}
//               controls
//             >
//               <source src={videoSrc} type="video/mp4" />
//             </video>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Video_1;
