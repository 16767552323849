import React from "react";
import FadeInSectionDownToUp from "../../Components/FadeAnimations/FadeInSectionDownToUp.js";

const About = () => {

  // const ExperimentVideos = [

  //   {
  //     videoSrc: '/Experiments/Out/3D Printing Machine - UAE.mp4',
  //     thumbnailSrc: '/Experiments/Thumbnail/3D Printer.png',
  //   },
  //   {
  //     videoSrc: '/Experiments/Out/UAE_INTELLECT EXP 1 Grape.mp4',
  //     thumbnailSrc: '/Experiments/Thumbnail/Grape Experiment.jpg',
  //   },
  //   {
  //     videoSrc: '/Experiments/Out/UAE_INTELLECT EXP 6 Eraser.mp4',
  //     thumbnailSrc: '/Experiments/Thumbnail/Rose Experiment.jpg',
  //   },
  //   {
  //     videoSrc: '/Experiments/Other Videos/MH Intellect Collage Final.mp4',
  //     thumbnailSrc: '',
  //   },
  //   {
  //     videoSrc: '/Experiments/Out/UAE_INTELLECT EXP 2 Balls.mp4',
  //     thumbnailSrc: '',
  //   },
  //   {
  //     videoSrc: '/Experiments/Out/UAE_INTELLECT EXP 3 Anti Freezing Liquid.mp4',
  //     thumbnailSrc: '',
  //   },
  //   {
  //     videoSrc: '/Experiments/Out/UAE_INTELLECT EXP 7 Balloon.mp4',
  //     thumbnailSrc: '',
  //   },
  //   {
  //     videoSrc: '/Experiments/Out/UAE_INTELLECT EXP 4 Clouds.mp4',
  //     thumbnailSrc: '',
  //   },
  //   {
  //     videoSrc: '/Experiments/Out/UAE_INTELLECT EXP 8 Pipe.mp4',
  //     thumbnailSrc: '',
  //   },
  //   {
  //     videoSrc: '/Experiments/Out/UAE_INTELLECT EXP 5 Rose.mp4',
  //     thumbnailSrc: '',
  //   },
  //   // {
  //   //   videoSrc: '/Experiments/Other Videos/MH Intellect_Testimonial KRM New.mp4',
  //   //   thumbnailSrc: '',
  //   // },
  //   // {
  //   //   videoSrc: '/Experiments/Other Videos/MH Intellect KRM 2 New.mp4',
  //   //   thumbnailSrc: '',
  //   // },


  // ]

  // const [playingVideo, setPlayingVideo] = useState(null);

  // const handlePlay = (videoSrc) => {
  //   setPlayingVideo(videoSrc);
  // };

  return (
    <section className="flex justify-center items-center bg-white dark:bg-black">
      <div className="container mx-auto max-w-5xl px-5 py-20">
        <div className="flex-row sm:flex justify-center items-start">
          <div className="font-bold text-6xl sm:text-9xl text-black dark:text-white">
            <FadeInSectionDownToUp>
              About
            </FadeInSectionDownToUp>
          </div>
          <FadeInSectionDownToUp>
            <div className="">
              <h1
                className="leading-tight font-bold text-9xl tracking-normal text-transparent bg-gradient-to-b from-pink-600 to-violet-600 bg-clip-text "
                style={{ fontSize: "15rem" }}
              >
                US.
              </h1>
              <p className="text-justify text-black dark:text-white">
                MH Intellect, a visionary initiative under the esteemed MH
                Cockpit Aviation, is dedicated to revolutionizing education by
                integrating cutting-edge technologies such as Artificial
                Intelligence, Machine Learning, Coding, and Robotics into the
                learning experience for young minds. As a subsidiary of MH
                Cockpit Aviation, a giant in the Indian aviation EdTech
                industry, MH Intellect benefits from a rich heritage of
                innovation and excellence in education.
              </p>
            </div>
          </FadeInSectionDownToUp>
        </div>
        {/* <div className="pt-5">
          <FadeInSectionDownToUp>
            <h1 className='text-8xl xl:text-7xl lg:text-6xl max-sm:text-5xl sm:text-6xl py-10 xl:py-10 max-sm:py-2 text-center leading-[80px] from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent'>
              Our Experiments.
            </h1>
          </FadeInSectionDownToUp>
        </div>
        <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-5">
          {ExperimentVideos.map((video, index) => (
            <div key={index}>
              <Video1
                videoSrc={video.videoSrc}
                thumbnailSrc={video.thumbnailSrc}
                className="rounded-3xl"
              />
            </div>
          ))}
        </div> */}
        {/* <div className="flex justify-center items-center my-28 sm:my-38">
          <video width="900" autoPlay poster="/Screenshot-thumb.png" className="rounded-3xl">
            <source
              src="/MH INTELLECT CORPORATE VIDEO.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <Video1
            videoSrc="/intellect final.mp4"
            thumbnailSrc="/Screenshot-thumb.png"
            className="rounded-3xl"
            isPlaying={playingVideo}
            onPlay={handlePlay}
          />
        </div> */}
        {/* <iframe src="https://mhcockpit.com" className="w-full h-[650px] my-20 rounded-3xl shadow-xl"></iframe> */}
        <div className="py-5">
          <FadeInSectionDownToUp>
            <div className="pb-10">
              <p className="text-gray-800 dark:text-white text-xl mb-2">Our Parent Company </p>
              <div className="flex justify-start items-center" >
                <a href="https://mhcockpit.com/" target="blank">
                  <h1 className="font-bold text-3xl sm:text-5xl text-black dark:text-white">
                    MH Cockpit Aviation
                  </h1>
                </a>
                <a href="https://mhcockpit.com/" target="blank">
                  <img src="/wings-red.png" alt="wing" className="w-12 sm:w-16" />
                </a>
              </div>
            </div>
            <p className="max-w-2xl text-justify text-black dark:text-white">
              MH Cockpit Aviation has a diverse portfolio in higher education
              and school education, covering fields such as aviation, artificial
              intelligence, robotics, STEM, and aerospace. With significant
              operations in both India and the Middle East under the name MHB
              Applied Studies, MH Cockpit Aviation has established itself as a
              leader in technology-driven education.
            </p>
          </FadeInSectionDownToUp>
        </div>
      </div>
    </section>
  );
};

export default About;
